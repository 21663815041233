import Api from "../../Services/Api";


const api = new Api();

export default (service, data) => dispatch => {
    
    dispatch({
        type: 'CRUD_ADD',
        data,
        payload:null
    })
    
    api.crud({
        endpoint: service.endpoint,
        actionType: "ADD",
        data
    }).then((response)=>{
        
        if(typeof response.data !== 'undefined'){
            dispatch({
                type:"CRUD_ADD_SUCCESS",
                payload: response
            })
        }else{
            dispatch({
                type:"CRUD_ADD_ERROR",
                payload:null,
            })
        }
    })
}