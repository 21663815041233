import Api from "../../Services/Api"; 

const api = new Api();

const CreateStore = ( storeName, endpoint, scenario, withToken ) => {
   
    withToken = (typeof withToken !== 'undefined') ? withToken : false
    let INITIAL_STATE = {
        action: storeName,
        data:null,
        payload:null,
        fetching:false,
        error:false,
        message:"",
    }
    const types = {
        INIT: storeName+"_INIT",
        SUCCESS: storeName+"_SUCCESS",
        ERROR: storeName+"_ERROR"
    }

    const reducer = (state = INITIAL_STATE, action) => {
      
        switch (action.type) {
          case types.INIT:
            return {
              data: action.data,
              fetching:true,
          };
          case types.SUCCESS:
            return {
              payload: action.payload,
              fetching:false,
              message: typeof action.payload.message !== 'undefined' ? action.payload.message : ''
          };
          case types.ERROR:
            return {
              data:null,
              error: true,
              fetching:false,
              payload:null,
              message: typeof action.payload.message !== 'undefined' ? action.payload.message : ''
          };
          default:
            return state;
        }
    };
    const reduxAction = (data) => dispatch => {
        console.log(endpoint, data, withToken);
        dispatch({
            type: types.INIT,
            data
        })
        
        api.call({
            endpoint: (typeof endpoint === 'function') ? endpoint(data) : endpoint,
            data,
            scenario,
        }, withToken).then((response)=>{
            
            if(typeof response.status !== 'undefined'){
                dispatch({
                    type: types.SUCCESS,
                    payload: response
                })
            }else{
                dispatch({
                    type: types.ERROR,
                    message: response.error
                })
            }
            
        })
      
    }
    return [reducer, reduxAction]
}

export default CreateStore;