import Api from "../../Services/Api";


const api = new Api();

export default (service, id, data) => dispatch => {
    
    dispatch({
        type: 'CRUD_UPDATE',
        data,
        payload:null
    })
    console.log({
        endpoint: service.endpoint,
        actionType: "UPDATE",
        id,
        data
    });
    api.crud({
        endpoint: service.endpoint,
        actionType: "UPDATE",
        id,
        data
    }).then((response)=>{
        
        if(typeof response.data !== 'undefined'){
            dispatch({
                type:"CRUD_UPDATE_SUCCESS",
                payload: response
            })
        }else{
            dispatch({
                type:"CRUD_UPDATE_ERROR",
                payload:null,
            })
        }
    })
}