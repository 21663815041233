import React from 'react'

export default function LabelAndText(props){
    const {label, text, html} = props
    return (
        <div style={{
            marginBottom:"10px"
        }}>
            <div style={{
                fontWeight:"bold",
            }}>
              {label}
            </div>
            {html ? ( <div> <span dangerouslySetInnerHTML={{__html: text}} /></div>) :  <div>{text}</div>}
           
        </div>
    )
}