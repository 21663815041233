import Api from "../../Services/Api";

const api = new Api();

export default opts => dispatch => {
  dispatch({
    type: "ACTION",
    data: opts,
    payload: null
  });

  api.do_action(opts).then(response => {
    if (response.status === 1) {
      dispatch({
        type: "ACTION_SUCCESS",
        payload: response
      });
    } else {
      dispatch({
        type: "ACTION_ERROR",
        payload: null
      });
    }
  });
};
